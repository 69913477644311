import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';

export const authGuard: CanActivateFn = (route, state) => {
  const router = inject(Router);
  const userData = JSON.parse(localStorage.getItem('userData') || '{}');
  if (userData?.access) {
    router.navigate(['/']);
  }
  return true;
};
