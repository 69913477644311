<div class="container gt-auth-block">
  <div class="row">
    <div class="col-xs-12 d-md-none d-sm-block">
      <div class="gt-logo-mobile">
        <img
          ngSrc="../../../assets/images/Guard_Tracker-Stacked-{{
            currentTheme
          }}.avif"
          alt="Guard Tracker"
          width="160"
          height="160"
          priority
          as="image"
        />
      </div>
    </div>
    <div class="col-xs-12 d-none d-md-block">
      <div class="gt-logo-desktop">
        <img
          ngSrc="../../../assets/images/Guard_Tracker-Lateral_Long_{{
            currentTheme
          }}.avif"
          alt="Guard Tracker"
          width="384"
          height="96"
          priority
          as="image"
        />
      </div>
    </div>
  </div>
  <div class="text-center">
    <p>
      We have received your request to close your account and delete your data.
    </p>
  </div>
</div>
