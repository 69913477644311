import { ChangeDetectorRef, Component } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { AppService } from '../../app.service';
import { ModelDialogueService } from '../../shared/components/modal-dialogue/model-dialogue.service';
import { SaveNewAddressComponent } from '../../shared/components/save-new-address/save-new-address.component';
import { DeviceInfoService } from '../../shared/services/device-info.service';
import { LoadingSpinnerService } from '../../shared/services/loading-spinner.service';
import { MustMatch } from '../../shared/validators/must-match-validator';
import { AuthService } from '../auth.service';
@Component({
  selector: 'gtapp-register',
  templateUrl: './register.component.html',
  styleUrl: './register.component.scss',
})
export class RegisterComponent {
  currentTheme: string = 'default';

  registerForm: UntypedFormGroup = new UntypedFormGroup(
    {
      add_trial: new UntypedFormControl(1),
      company_name: new UntypedFormControl('', [Validators.required]),
      addressLookup: new UntypedFormControl(''),
      address1: new UntypedFormControl('', [Validators.required]),
      address2: new UntypedFormControl('', [Validators.required]),
      address3: new UntypedFormControl(''),
      city_name: new UntypedFormControl('', [Validators.required]),
      postcode: new UntypedFormControl(
        '',
        Validators.compose([
          Validators.required,
          Validators.min(800),
          Validators.max(9999),
          Validators.maxLength(4),
        ])
      ),
      longitude: new UntypedFormControl('', [Validators.required]),
      latitude: new UntypedFormControl('', [Validators.required]),
      country_name: new UntypedFormControl('Australia', Validators.max(50)),
      email: new UntypedFormControl('', [
        Validators.required,
        Validators.email,
      ]),
      vCode: new UntypedFormControl(''),
      state_code: new UntypedFormControl(''),
      first_name: new UntypedFormControl('', [Validators.required]),
      last_name: new UntypedFormControl('', [Validators.required]),
      password: new UntypedFormControl(null, [
        Validators.required,
        Validators.minLength(8),
      ]),
      confirm_password: new UntypedFormControl(null, [
        MustMatch('password', 'confirm_password'),
      ]),
      mobile_number: new UntypedFormControl(null),
    },
    {
      validators: MustMatch('password', 'confirm_password'),
    }
  );
  showAddressLookup: boolean = true;

  enteredAddresslookup: boolean = false;
  addressLookupValue: any;
  submitted: any;

  error: any;

  addrLookupData: any;

  stateData: any[] = [];

  alreadyRegistered: boolean = false;
  isPolicyAccepted: any = false;

  gotVerificationCode: boolean = false;
  verificationToken: any;

  showPassword: boolean = false;
  currentStep: number = 1;
  constructor(
    private spinnerService: LoadingSpinnerService,
    private cd: ChangeDetectorRef,
    private appService: AppService,
    private dialogService: ModelDialogueService,
    private authService: AuthService,
    public router: Router,
    private deviceInfoService: DeviceInfoService
  ) {}
  isVerificationTokenValid() {
    if (!localStorage.getItem('verificationToken')) return false;
    let currentTime = new Date().getTime();
    let expiryTokenTime: any = localStorage.getItem('verificationExpiry');
    if (!expiryTokenTime) return false;
    return currentTime < JSON.parse(expiryTokenTime);
  }

  ngOnInit(): void {
    this.getStates(1);
    document.documentElement.setAttribute('data-bs-theme', 'light');
    var element = document.querySelector('nb-card');
    element?.setAttribute('id', 'registerCard');
    // Call the get_IP API if IP address is not capture of user
    if (!localStorage.getItem('ipAddress')) {
      this.deviceInfoService.setIp();
    }
    this.spinnerService.show();
    // Check and Fetch the register details if it exists
    let registerDetails: any = localStorage.getItem('registerDetails');
    registerDetails = JSON.parse(registerDetails);
    if (registerDetails?.expiry) {
      if (new Date().getTime() > registerDetails?.expiry) {
        localStorage.removeItem('registerDetails');
      } else {
        this.registerForm.patchValue(registerDetails?.values);
        this.enteredAddresslookup = true;
        if (
          localStorage.getItem('verificationToken') &&
          this.isVerificationTokenValid()
        ) {
          this.gotVerificationCode = true;
        }

        if (this.registerForm.valid) {
          this.currentStep = 5;
        } else {
          this.currentStep = 1;
        }
      }
    }
    this.deviceInfoService.deviceInfoSubject.subscribe((value: any) => {
      if (
        'permissionStatus' in value &&
        value?.permissionStatus === 'locationDisabled'
      ) {
        this.error = 'Error: Location access not given';
      }
    });
    this.spinnerService.hide();
    this.registerForm
      .get('address1')
      ?.valueChanges.subscribe(() => this.checkAddressValidity());
    this.registerForm
      .get('address2')
      ?.valueChanges.subscribe(() => this.checkAddressValidity());
    this.registerForm
      .get('city_name')
      ?.valueChanges.subscribe(() => this.checkAddressValidity());
    this.registerForm
      .get('postcode')
      ?.valueChanges.subscribe(() => this.checkAddressValidity());
    this.registerForm
      .get('state_code')
      ?.valueChanges.subscribe(() => this.checkAddressValidity());
  }
  checkAddressValidity() {
    const address1Valid = this.registerForm.get('address1')?.valid;
    const address2Valid = this.registerForm.get('address2')?.valid;
    const cityValid = this.registerForm.get('city_name')?.valid;
    const postcodeValid = this.registerForm.get('postcode')?.valid;
    const stateValid = this.registerForm.get('state_code')?.valid;

    // If all address fields are valid, hide the address lookup input
    this.showAddressLookup = !(
      address1Valid &&
      address2Valid &&
      cityValid &&
      postcodeValid &&
      stateValid
    );
  }

  getStates(countryId: any) {
    this.appService.getStates(+countryId).subscribe((response: any) => {
      if (response['status'] == 'success') {
        this.stateData = response['data'];
      } else {
      }
    });
  }
  addressSelected(address: any) {
    if (address.key === 0) {
      this.addPlace();
    } else if (address && typeof address === 'object') {
      this.enteredAddresslookup = true;
      this.addrLookupData = undefined;
      this.registerForm.patchValue({
        address1: address?.address1,
        address2: address?.address2,
        address3: address?.address3,
        city_name: address?.city_name,
        postcode: address?.postcode,
        state_code: address?.state_code,
        country: address?.country_name,
        latitude: address?.latitude,
        longitude: address?.longitude,
        addressLookup: address.full_address,
      });
      this.addressLookupValue = address.full_address;
      if (this.registerForm.valid) {
        this.showAddressLookup = false;
      }
      setTimeout(() => {
        window.scrollTo(0, document.body.scrollHeight);
      }, 100);
    }
  }

  onAddressSearch(addressSearch: any) {
    if (addressSearch?.target?.value?.length > 2) {
      this.appService
        .addressLookupShared(addressSearch.target.value)
        .subscribe((res: any) => {
          this.addrLookupData = res['data'];
        });
      this.enteredAddresslookup = false;
    }
  }

  acceptPrivacyPolicy(event: any) {
    this.isPolicyAccepted = event.target.checked;
  }
  sendVerificationCode() {
    const isVerificationCodeValid = this.isVerificationTokenValid();
    if (isVerificationCodeValid) return;
    this.authService
      .sendVerificationCode(
        this.registerForm.controls['email'].value,
        this.registerForm.controls['first_name'].value,
        this.registerForm.controls['last_name'].value
      )
      .subscribe((response: any) => {
        if (response['status'] == 'success') {
          this.gotVerificationCode = true;
          this.verificationToken = response['token'];
          //Save the token and expiration time which will expire in 10 minutes
          let verificationExpiry = new Date().getTime() + 10 * 60 * 1000;
          localStorage.setItem(
            'verificationExpiry',
            JSON.stringify(verificationExpiry)
          );
          localStorage.setItem('verificationToken', this.verificationToken);
        } else {
          this.error = response['message'];
          if (response?.sign_in && this.registerForm.controls['email'].value) {
            this.alreadyRegistered = true;
          }
        }
      });
  }
  // Method to verify the verification code sent in user's email
  verifyVerificationCode(event: any) {
    if (event?.target?.value?.length >= 4) {
      this.spinnerService.show();
      this.verificationToken = localStorage.getItem('verificationToken')
        ? localStorage.getItem('verificationToken')
        : this.verificationToken;
      const body = { v_code: this.registerForm.controls['vCode'].value };
      this.appService
        .gtExternalLink(
          'external_api/verify_v_code',
          body,
          this.verificationToken
        )
        .then((response: any) => {
          if (response['status'] == 'success') {
            this.verificationToken = response['token'];
            localStorage.setItem('verificationToken', this.verificationToken);
            this.error = '';
            this.finalRegister();
          } else {
            this.error = response['message'];

            if (
              response?.sign_in &&
              this.registerForm.controls['email'].value
            ) {
              this.alreadyRegistered = true;
            }
            this.spinnerService.hide();
            if (response['restart'] === true) {
              this.gotVerificationCode = false;
              localStorage.removeItem('registerDetails');
              localStorage.removeItem('verificationToken');
              localStorage.removeItem('verificationExpiry');
              this.error = 'We could not verify your account';
            }
          }
        });
    }
  }

  //Method to register the user after verifying email
  finalRegister(): void {
    if (this.registerForm.valid) {
      this.spinnerService.show();
      this.submitted = true;
      this.appService
        .gtExternalLink(
          'register_login/register',
          this.registerForm.value,
          this.verificationToken
        )
        .then((response: any) => {
          this.submitted = false;
          if (response) {
            if (response['status'] == 'success') {
              localStorage.removeItem('registerDetails');
              this.appService.setUserData(response);
              this.spinnerService.hide();
              localStorage.setItem('firstTimeSubscriber', 'true');
              setTimeout(() => {
                this.router.navigate(['/']);
              }, 100);
            } else {
              this.error = response['message'];
              this.spinnerService.hide();
            }
          }
          this.cd.detectChanges();
        });
    } else {
    }
  }

  // Set the Registration details in local-storage
  saveRegisterDetail() {
    const item = {
      values: this.registerForm.value,
      expiry: new Date().getTime() + 2 * 60 * 60 * 1000,
    };
    localStorage.setItem('registerDetails', JSON.stringify(item));
  }

  addPlace() {
    const dialogRef = this.dialogService.open(SaveNewAddressComponent, {
      data: {},
    });
    dialogRef.afterClosed().subscribe((value: any) => {
      if (value && value != 'close') {
        this.addressSelected(value);
      }
    });
  }
  refresh(): void {
    localStorage.clear();
    window.location.reload();
  }
  forceUppercaseConditionally(event: any) {
    this.registerForm.controls['city_name'].setValue(
      event.target.value.toUpperCase()
    );
  }

  isInvalid(controlName: string): boolean {
    const control = this.registerForm.get(controlName);
    return control ? control.invalid : false;
  }

  isInvalidAddress(): boolean {
    return (
      this.isInvalid('address1') ||
      this.isInvalid('address2') ||
      this.isInvalid('postcode') ||
      this.isInvalid('city_name')
    );
  }
  goToStep(stepCount: number) {
    this.currentStep = stepCount;
    if (stepCount === 1) {
      this.isPolicyAccepted = false;
    }
  }
  onNext() {
    if (
      this.registerForm.controls['password'].valid &&
      this.registerForm.controls['confirm_password'].valid
    ) {
      this.goToStep(5);
    }
  }
  toggleShowPassword() {
    this.showPassword = !this.showPassword;
  }
  goToLogin() {
    if (this.registerForm.controls['email'].value) {
      localStorage.setItem('email', this.registerForm.controls['email'].value);
    }
    this.router.navigate(['/login']);
  }
}
