import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { currentTheme } from '../../../global.variable';

@Component({
  selector: 'gtapp-delete-account',
  templateUrl: './delete-account.component.html',
  styleUrl: './delete-account.component.scss',
})
export class DeleteAccountComponent {
  currentTheme: string = currentTheme;

  constructor(private router: Router) {
    if (localStorage.getItem('from') !== 'deletion') {
      this.router.navigate(['/404']);
    }
    localStorage.clear();
  }
}
