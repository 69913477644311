<div id="registration" class="container gt-auth-block">
  <div class="row">
    <div class="col-xs-12 d-md-none d-sm-block">
      <div class="gt-logo-mobile">
        <img
          ngSrc="../../../assets/images/Guard_Tracker-Stacked-{{
            currentTheme
          }}.avif"
          alt="Guard Tracker"
          width="160"
          height="160"
          priority
          as="image"
        />
      </div>
    </div>
    <div class="col-xs-12 d-none d-md-block">
      <div class="gt-logo-desktop">
        <img
          ngSrc="../../../assets/images/Guard_Tracker-Lateral_Long_{{
            currentTheme
          }}.avif"
          alt="Guard Tracker"
          width="384"
          height="96"
          priority
          as="image"
        />
      </div>
    </div>
  </div>

  <div class="text-center mb-5" *ngIf="!alreadyRegistered">
    <h4>Sign Up</h4>
  </div>

  <div *ngIf="error" class="alert alert-danger" role="alert">
    {{ error }}
  </div>
  @if(alreadyRegistered){
  <ng-container>
    <div class="text-center">
      <button class="btn btn-primary w-50 btn-lg" (click)="goToLogin()">
        Sign In
      </button>
    </div>
  </ng-container>
  }@else {
  <ng-container>
    <!-- Registration Form starts -->
    <form [formGroup]="registerForm" #form="ngForm" aria-labelledby="title">
      <!-- Step 1: Company and Address -->
      <div *ngIf="currentStep === 1">
        <h5 class="mb-3">Step 1: Your Company Details</h5>
        <div class="row row-col-1 g-2">
          <div class="form-floating">
            <input
              class="form-control"
              type="text"
              formControlName="company_name"
              id="company_name"
              placeholder="Company Name"
            />
            <label for="company_name">Company Name</label>
          </div>
          <!-- Address -->
          <div class="mb-3" *ngIf="showAddressLookup">
            <gtapp-auto-complete
              [data]="addrLookupData"
              placeHolder="Address Lookup"
              searchKeyword="full_address"
              [initialValue]="addressLookupValue"
              [itemTemplate]="addressTemplate"
              [addInitialKey]="true"
              (inputChanged)="onAddressSearch($event)"
              (selected)="addressSelected($event)"
            >
            </gtapp-auto-complete>
          </div>

          <ng-template #addressTemplate let-item>
            @if (item?.key === 0) {
            <div>Enter address manually</div>
            } @else {
            <div class="small fw-semibold">
              {{ item?.full_address }}
            </div>

            }
          </ng-template>

          <div
            class="d-flex flex-column gap-2"
            *ngIf="enteredAddresslookup === true"
          >
            <div
              class="alert alert-danger"
              role="alert"
              *ngIf="isInvalidAddress()"
            >
              Unable to get exact address, please fill in missing information
            </div>
            <div class="form-floating">
              <input
                [ngClass]="{ 'is-invalid': isInvalid('address1') }"
                class="form-control"
                type="tel"
                id="address1"
                formControlName="address1"
                placeholder="Street Number"
              />
              <label for="address1">Street Number</label>
            </div>
            <div class="form-floating">
              <input
                [ngClass]="{ 'is-invalid': isInvalid('address2') }"
                class="form-control"
                type="text"
                id="address2"
                formControlName="address2"
                autocomplete="off"
                placeholder="Street"
              />
              <label for="address2">Street Name</label>
            </div>
            <div class="form-floating">
              <input
                class="form-control"
                type="text"
                id="address3"
                formControlName="address3"
                autocomplete="off"
                placeholder="Unit"
              />
              <label for="address3">Address Prefix (Suite/Lot/Unit)</label>
            </div>
            <div class="form-floating">
              <input
                [ngClass]="{ 'is-invalid': isInvalid('city_name') }"
                class="form-control"
                type="text"
                id="city_name"
                formControlName="city_name"
                autocomplete="off"
                (input)="forceUppercaseConditionally($event)"
                placeholder="City"
              />
              <label for="city_name">Suburb</label>
            </div>
            <div class="form-floating">
              <input
                [ngClass]="{ 'is-invalid': isInvalid('postcode') }"
                class="form-control"
                type="tel"
                id="postcode"
                formControlName="postcode"
                autocomplete="off"
                placeholder="Post Code"
                [maxlength]="4"
              />
              <label for="postcode">Post Code</label>
            </div>
            <div class="form-floating">
              <select
                id="stateDp"
                class="form-select form-select-lg"
                formControlName="state_code"
              >
                <option value="" selected disabled>State</option>
                <option *ngFor="let state of stateData" [value]="state.code">
                  {{ state.code }}
                </option>
              </select>
              <label for="stateDp">State</label>
            </div>
          </div>

          <!-- Agreement Checkbox -->
          <div id="agree">
            <div class="form-check d-flex">
              <input
                class="form-check-input"
                type="checkbox"
                id="accept_policy"
                [checked]="isPolicyAccepted"
                (click)="acceptPrivacyPolicy($event)"
              />

              <label for="accept_policy" class="cursor-pointer ms-2">
                I agree to the
                <span>
                  <a
                    class="terms-link pointer"
                    href="https://guardtracker.com.au/terms.html"
                    target="_blank"
                    >Terms of Service</a
                  >
                  and
                  <a
                    class="terms-link pointer"
                    href="https://guardtracker.com.au/privacy.html"
                    target="_blank"
                    >Privacy Policy</a
                  >
                </span>
              </label>
            </div>
          </div>
        </div>

        <div class="row mt-2">
          <div class="col-6 offset-6">
            <button
              type="button"
              class="btn btn-primary w-100 btn-lg"
              (click)="saveRegisterDetail(); goToStep(2)"
              [disabled]="
                registerForm.controls['company_name'].invalid ||
                registerForm.controls['address1'].invalid ||
                registerForm.controls['address2'].invalid ||
                registerForm.controls['city_name'].invalid ||
                registerForm.controls['postcode'].invalid ||
                !isPolicyAccepted
              "
            >
              Next
            </button>
          </div>
        </div>
      </div>

      <div *ngIf="currentStep === 2">
        <h5>Step 2: Your Name</h5>
        <div class="row g-2">
          <div class="form-floating">
            <input
              [class.is-invalid]="
                registerForm.controls['first_name'].invalid &&
                registerForm.controls['first_name'].touched
              "
              class="form-control"
              type="text"
              id="first_name"
              formControlName="first_name"
              maxlength="150"
              autocomplete="off"
              placeholder="First Name"
            />
            <label for="first_name">First Name</label>
            <div
              *ngIf="
                registerForm.controls['first_name'].invalid &&
                registerForm.controls['first_name'].touched
              "
              class="text-danger"
            >
              Please provide a valid first name.
            </div>
          </div>
          <div class="form-floating">
            <input
              [class.is-invalid]="
                registerForm.controls['last_name'].invalid &&
                registerForm.controls['last_name'].touched
              "
              class="form-control"
              type="text"
              id="last_name"
              formControlName="last_name"
              autocomplete="off"
              placeholder="Last Name"
              maxlength="150"
            />
            <label for="last_name">Last Name</label>
            <div
              *ngIf="
                registerForm.controls['last_name'].invalid &&
                registerForm.controls['last_name'].touched
              "
              class="text-danger"
            >
              Please provide a valid last name.
            </div>
          </div>
        </div>

        <div class="row row-cols-2 gy-2 mt-2">
          <div class="col">
            <button
              type="button"
              class="btn btn-dark w-100 btn-lg"
              (click)="goToStep(1)"
            >
              Back
            </button>
          </div>
          <div class="col">
            <button
              type="button"
              class="btn btn-primary w-100 btn-lg"
              (click)="saveRegisterDetail(); goToStep(3)"
              [disabled]="
                registerForm.controls['first_name'].invalid ||
                registerForm.controls['last_name'].invalid
              "
            >
              Next
            </button>
          </div>
        </div>
      </div>
      <div class="mb-3" *ngIf="currentStep === 3">
        <h5>Step 2: Email Address</h5>
        <div class="form-floating">
          <input
            class="form-control"
            type="email"
            id="email"
            formControlName="email"
            autocomplete="off"
            placeholder="Email Address"
          />
          <label for="email">Email Address</label>
        </div>
        <div
          *ngIf="
            registerForm.controls['email'].invalid &&
            registerForm.controls['email'].touched
          "
          class="text-danger"
        >
          Please provide a valid Email.
        </div>
        <div class="row row-cols-2 gy-2 mt-2">
          <div class="col">
            <button
              type="button"
              class="btn btn-dark w-100 btn-lg"
              (click)="goToStep(2)"
            >
              Back
            </button>
          </div>
          <div class="col">
            <button
              type="button"
              class="btn btn-primary w-100 btn-lg"
              (click)="
                goToStep(4); sendVerificationCode(); saveRegisterDetail()
              "
              [disabled]="registerForm.controls['email'].invalid"
            >
              Next
            </button>
          </div>
        </div>
      </div>
      <div *ngIf="currentStep === 4">
        <h5>Step 3: Password</h5>
        <div class="row g-2">
          <div class="input-group">
            <div class="form-floating">
              <input
                class="form-control"
                [type]="showPassword ? 'text' : 'password'"
                name="password"
                id="password"
                formControlName="password"
                placeholder="New Password"
                minlength="8"
                [ngClass]="{
                'is-invalid':
                  registerForm.controls['password'].dirty &&
                  registerForm.controls['password'].invalid,
                
              }"
              />
              <label for="password">New Password</label>
            </div>
            <span
              class="input-group-text"
              *ngIf="showPassword === true"
              (click)="toggleShowPassword()"
            >
              <i class="fa-regular fa-eye"></i>
            </span>
            <span
              class="input-group-text"
              *ngIf="showPassword === false"
              (click)="toggleShowPassword()"
            >
              <i class="fa-regular fa-eye-slash"></i>
            </span>
          </div>
          <small
            *ngIf="registerForm.controls['password'].hasError('minlength')"
            class="text-danger"
          >
            Must have at least 8 characters
          </small>
          <div class="form-floating mt-3">
            <input
              class="form-control"
              [type]="showPassword ? 'text' : 'password'"
              id="confirm_password"
              formControlName="confirm_password"
              autocomplete="off"
              placeholder="Confirm Password"
              (keydown.enter)="onNext()"
            />
            <label for="confirm_password">Confirm Password</label>
          </div>
          <small
            class="text-danger"
            *ngIf="
              registerForm.controls['confirm_password'].value &&
              registerForm.controls['confirm_password'].invalid
            "
          >
            Passwords do not match
          </small>
        </div>

        <div class="row row-cols-2 mt-2">
          <div class="col">
            <button
              type="button"
              class="btn btn-dark w-100 btn-lg"
              (click)="goToStep(3)"
            >
              Back
            </button>
          </div>
          <div class="col">
            <button
              type="submit"
              class="btn btn-primary w-100 btn-lg"
              (click)="goToStep(5); saveRegisterDetail()"
              [disabled]="
                registerForm.controls['password'].invalid ||
                registerForm.controls['confirm_password'].invalid
              "
            >
              Next
            </button>
          </div>
        </div>
      </div>
      <div *ngIf="currentStep === 5">
        <ng-container *ngIf="gotVerificationCode">
          <h5>Step 5: Verification</h5>
          <div class="mb-2">
            Please enter the verification code we sent to your email
            <span class="text-primary">{{ registerForm.value?.email }}</span>
          </div>
          <div class="form-floating mb-5">
            <input
              nbInput
              [maxlength]="4"
              oninput="this.value = this.value.toUpperCase()"
              class="form-control"
              type="tel"
              name="vCode"
              id="ver-vode"
              formControlName="vCode"
              placeholder="Verification Code"
              (input)="verifyVerificationCode($event)"
            />
            <label for="ver-vode">Verification Code</label>
          </div>
        </ng-container>
        <div class="text-center">
          <button class="btn text-info" (click)="refresh()">
            Restart Registration
          </button>
        </div>
      </div>
    </form>
  </ng-container>
  }
</div>
