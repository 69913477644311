import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { currentTheme } from '../../../global.variable';
import { AppService } from '../../app.service';
import { DataCheckService } from '../../shared/services/data-check.service';
import { LoadingSpinnerService } from '../../shared/services/loading-spinner.service';

@Component({
  selector: 'gtapp-error-page503',
  templateUrl: './error-page503.component.html',
  styleUrl: './error-page503.component.scss',
})
export class ErrorPage503Component implements OnInit {
  currentTheme: string = currentTheme;
  userData = this.appService.getUserData();
  isSuperUser = this.dataCheckService.isSuperUser();
  countDownValue: number = 0;
  countDownInterval: any;
  constructor(
    private spinnerService: LoadingSpinnerService,
    private dataCheckService: DataCheckService,
    private appService: AppService,
    private router: Router
  ) {}
  async ngOnInit(): Promise<void> {
    if (await this.isServerOnline()) {
      this.goToHome();
    } else {
      this.checkServerStatus();
    }

    this.spinnerService.hide();
  }

  ngOnDestroy(): void {
    if (this.countDownInterval) clearInterval(this.countDownInterval);
  }
  goToHome() {
    if (this.userData && this.userData.access) {
      if (this.isSuperUser === true) {
        this.router.navigate(['gtadmin/dashboard']);
      } else {
        this.router.navigate(['/']);
      }
    } else {
      this.router.navigate(['/login']);
    }
  }
  checkServerStatus() {
    // 1000 = 1 second
    const checkInterval = 3000;
    this.countDownValue = checkInterval / 1000;
    this.updateCountdown();

    setTimeout(async () => {
      if (await this.isServerOnline()) {
        this.goToHome();
      } else {
        this.checkServerStatus();
      }
    }, checkInterval);
  }
  updateCountdown() {
    if (this.countDownInterval) {
      clearInterval(this.countDownInterval);
    }

    this.countDownInterval = setInterval(() => {
      if (this.countDownValue > 0) {
        this.countDownValue--;
      } else {
        clearInterval(this.countDownInterval);
      }
    }, 1000);
  }
  isServerOnline() {
    this.spinnerService.show();

    return new Promise((resolve) => {
      try {
        this.appService.getCountries().subscribe({
          next: (response: any) => {
            resolve(response?.status === 'success');

            this.spinnerService.hide();
          },
          error: (err) => {
            resolve(false);

            this.spinnerService.hide();
          },
        });
      } catch (error) {
        resolve(false);

        this.spinnerService.hide();
      }
    });
  }
}
