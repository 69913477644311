import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';

import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { environment } from '../../environments/environment';
import { SharedModule } from '../shared/shared.module';
import { AuthRoutingModule } from './auth-routing.module';
import { AuthService } from './auth.service';
import { DeleteAccountComponent } from './delete-account/delete-account.component';
import { ErrorPage503Component } from './error-page503/error-page503.component';
import { ExternalAppComponent } from './external-app/external-app.component';
import { LoginComponent } from './login/login.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { RegisterComponent } from './register/register.component';
const recaptchaV3SiteKey = environment.recaptcha.siteKey;

@NgModule({
  declarations: [
    LoginComponent,
    RegisterComponent,
    ExternalAppComponent,
    PageNotFoundComponent,
    ErrorPage503Component,
    DeleteAccountComponent,
  ],
  imports: [
    CommonModule,
    AuthRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    SharedModule,
    NgOptimizedImage,
  ],
  providers: [AuthService],
})
export class AuthModule {}
